import { createRouter, createWebHashHistory } from 'vue-router'
import SignInTemplate from '../templates/SignInTemplate.vue';
import DashboardTemplate from '../templates/DashboardTemplate.vue';


const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: DashboardTemplate
    },
    {
        path: '/login',
        name: 'Login',
        component: SignInTemplate,
    },
    {
        path: '/register',
        name: 'Register',
        component: SignInTemplate,
    },
    {
        path: '/branch',
        name: 'BranchList',
        component: DashboardTemplate,
        children: [
            {
                path: 'add',
                name: 'BranchForm',
                component: DashboardTemplate,
            },
            {
                path: 'category',
                name: 'CategoryList',
                component: DashboardTemplate,
                children: [
                    {
                        path: 'add',
                        name: 'CategoryForm',
                        component: DashboardTemplate,
                    }
                ]
            },
            {
                path: 'menu',
                name: 'MenuList',
                component: DashboardTemplate,
                children: [
                    {
                        path: 'add',
                        name: 'MenuForm',
                        component: DashboardTemplate,
                    }
                ]
            }
        ]
    },
]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router 