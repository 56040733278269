<template>
    <form action="#" @submit.prevent="Register">
        <div class="mb-3">
            <label for="exampleInputtext1" class="form-label">Name</label>
            <input id="name" type="name" class="form-control" name="name" value required autofocus v-model="name" />
        </div>
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email Address</label>
            <input id="email" type="email" class="form-control" name="email" value required autofocus v-model="email" />
        </div>
        <div class="mb-4">
            <label for="exampleInputPassword1" class="form-label">Password</label>
            <input id="password" type="password" class="form-control" name="password" required v-model="password" />
        </div>
        <a href="./index.html" class="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">Sign Up</a>
        <div class="text-center">
            <div class="row">
                <p class="fs-4 mb-0 fw-bold">Already have an Account?</p>
            </div>
            <div class="row">
                <router-link class="text-primary fw-bold ms-2" to="/login">Sign In</router-link>
            </div>
        </div>
    </form>
</template>


<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: "RegisterComponent",
    setup() {
        const name = ref('')
        const email = ref('')
        const password = ref('')
        const error = ref(null)

        const store = useStore()
        const router = useRouter()

        const Register = async () => {
            try {
                await store.dispatch('register', {
                    email: email.value,
                    password: password.value,
                    name: name.value
                })
                router.push('/')
            }
            catch (err) {
                error.value = err.message
            }
        }

        return { Register, name, email, password, error }
    }
};
</script>