<template>
    <div class="row">
        <div class="col-12 text-right">
            <router-link class="btn btn-primary mx-1" to="/branch/">Back</router-link>
            <router-link class="btn btn-success mx-1" to="/branch/category/add">Add Category</router-link>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Name (EN)</th>
                <th scope="col">Name (ES)</th>
                <th scope="col">Order</th>
                <th scope="col">Visible</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="category in categories" :key="category.id">
                <td>{{ category.name.en }}</td>
                <td>{{ category.name.es }}</td>
                <td>{{ category.order }}</td>
                <td>{{ category.isVisible }}</td>
                <td>
                    <button class="btn btn-info mx-1" @click="edit(category.id)">Edit</button>
                    <button class="btn btn-danger mx-1" @click="delete(category.id)">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { db } from '../../firebaseConfig'
import { collection, query, orderBy, onSnapshot } from "firebase/firestore"

export default {
    setup() {
        const router = useRouter()
        const store = useStore()
        const categories = ref([])
        
        async function deleteCategory() {
            await store.dispatch('deleteCategory')
        }

        const edit = (id) => {
            saveCategory(id)
            router.push('/branch/category/add')
        }

        async function saveCategory(id) {
            let data = categories.value.find(x => x.id === id)
            await store.dispatch('addCategory', data)
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/${store.getters.branch.data.id}/Category`), orderBy('order'))
            onSnapshot(q, (querySnapshot) => {
                categories.value = []
                querySnapshot.forEach((doc) => {
                    categories.value.push({ id: doc.id, ...doc.data() })
                });
            });
        }
        
        onMounted(() => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/')
            } else {
                loadData()
                deleteCategory()
            }
        })

        return { categories, edit };
    }
}
</script>