<template>
    <div class="row">
        <div class="col-12">
            <router-link class="btn btn-primary mb-3" to="/branch/category/">Back</router-link>
        </div>
    </div>
    <form ref="form" @submit.prevent="submitForm">
        <div class="row">
            <div class="col-6">
                <div class="mb-3">
                    <label for="branchNameEn">Name EN</label>
                    <input v-model="categoryForm.name.en" type="text" class="form-control" id="branchNameEn" required>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label for="branchNameEs">Name ES</label>
                    <input v-model="categoryForm.name.es" type="text" class="form-control" id="branchNameEs" required>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <label for="branchOrder">Order</label>
            <input v-model="categoryForm.order" type="number" class="form-control" id="branchOrder" required>
        </div>
        <div class="mb-3 form-check">
            <input v-model="categoryForm.isVisible" type="checkbox" class="form-check-input" id="branchIsVisible">
            <label class="form-check-label" for="branchIsVisible">Is Visible</label>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
    </form>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { ref, onMounted, reactive } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification";
import { db } from '../../firebaseConfig'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()
        const isEdit = ref(false)
        var id = null

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        const formInitialState = {
            name: {
                en: '',
                es: '',
            },
            order: 0,
            isVisible: false,
        }

        const categoryForm = reactive({ ...formInitialState })

        const submitForm = async () => {
            loader = $loading.show()
            addToCollection()
            .then(() => {
                loader.hide()
            })
        }

        function initForm() {
            Object.assign(categoryForm, store.getters.category.data)
        }

        // function resetForm() {
        //     Object.assign(categoryForm, ...branchInitialState)
        // }

        async function addToCollection() {
            if(isEdit.value) {
                let docRef = doc(db, ConfigMethods.MAIN_URL+'/'+id+'/Category/'+categoryForm.id);
                delete categoryForm.id
                
                await updateDoc(docRef, categoryForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                    router.push('/branch/category/')
                })
                .catch((err) => console.log(err));
            } else {
                await addDoc(collection(db, ConfigMethods.MAIN_URL+'/'+id+'/Category/'), categoryForm)
                .then((docRef) => {
                    console.log(docRef.id)
                    if (docRef.id) {
                        notify({
                            type: "success",
                            title: "It was saved correctly"
                        })
                        router.push('/branch/category/')
                    }
                })
                .catch((err) => console.log(err));
            }
        }

        onMounted(async () => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/category/')
            } else {
                id = store.getters.branch.data.id
                if( store.getters.category.data != null ) {
                    isEdit.value = true
                    initForm()
                }
            }
        });

        return { categoryForm, submitForm  };
    },
    components: {
        
    }
}
</script>