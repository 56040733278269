<template>
    <!-- Sidebar Start -->
    <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div>
            <div class="brand-logo d-flex align-items-center justify-content-between">
                <a href="./index.html" class="text-nowrap logo-img">
                    <img :src="require('@/assets/images/logos/dark-logo.svg')" width="180">
                </a>
                <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                    <i class="ti ti-x fs-8"></i>
                </div>
            </div>
            <!-- Sidebar navigation-->
            <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
                <ul id="sidebarnav">
                    <li class="nav-small-cap">
                        <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                        <span class="hide-menu">CellF Order</span>
                    </li>
                    <li class="sidebar-item">
                        <a class="sidebar-link" href="./index.html" aria-expanded="false">
                            <span>
                                <i class="ti ti-layout-dashboard"></i>
                            </span>
                            <span class="hide-menu">Branches</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!--  Sidebar End -->
    <!--  Main wrapper -->
    <div class="body-wrapper">
        <!--  Header Start -->
        <header class="app-header">
            <nav class="navbar navbar-expand-lg navbar-light">
                <ul class="navbar-nav">
                    <li class="nav-item d-block d-xl-none">
                        <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                            <i class="ti ti-menu-2"></i>
                        </a>
                    </li>
                </ul>
                <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                    <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                        <li class="nav-item dropdown">
                            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img :src="require('@/assets/images/profile/user-1.jpg')" alt="" width="35" height="35" class="rounded-circle">
                            </a>
                            <div v-if="user.loggedIn" class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                <div class="message-body">
                                    <p class="text-center">{{ user.data.displayName }}</p>
                                    <a href="javascript:void(0)" @click.prevent="signOut()" class="btn btn-outline-primary mx-3 mt-2 d-block">Logout</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <!--  Header End -->
        <div class="container-fluid">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <BranchList v-if="isBranchList" />
                        <BranchForm v-if="isBranchForm" />
                        <CategoryList v-if="isCategoryList" />
                        <CategoryForm v-if="isCategoryForm" />
                        <MenuList v-if="isMenuList" />
                        <MenuForm v-if="isMenuForm" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
// import { router, store } from '../helper'
import { auth } from '../firebaseConfig'
import BranchList from '../views/branch/BranchList.vue';
import BranchForm from '../views/branch/BranchForm.vue';
import CategoryList from '../views/category/CategoryList.vue';
import CategoryForm from '../views/category/CategoryForm.vue';
import MenuList from '../views/menu/MenuList.vue';
import MenuForm from '../views/menu/MenuForm.vue';

export default {
    name: "DashboardTemplate",
    computed: {
        isBranchList() {
            return this.$route.name === 'BranchList'
        },
        isBranchForm() {
            return this.$route.name === 'BranchForm'
        },
        isCategoryList() {
            return this.$route.name === 'CategoryList'
        },
        isCategoryForm() {
            return this.$route.name === 'CategoryForm'
        },
        isMenuList() {
            return this.$route.name === 'MenuList'
        },
        isMenuForm() {
            return this.$route.name === 'MenuForm'
        },
    },
    setup() {
        const store = useStore()
        const router = useRouter()

        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
            if(!user) {
                router.push('/login')
            }
        });

        const user = computed(() => {
            return store.getters.user;
        });

        const signOut = async () => {
            await store.dispatch('logOut')
            router.push('/login')
        }

        return { user, signOut }
    },
    components: {
        BranchList,
        BranchForm,
        CategoryList,
        CategoryForm,
        MenuList,
        MenuForm
    }
};
</script>