<template>
    <div class="row">
        <div class="col-12 text-right">
            <router-link class="btn btn-success btn-md" to="/branch/add">Add Branch</router-link>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Name (EN)</th>
                <th scope="col">Name (ES)</th>
                <th scope="col">Order</th>
                <th scope="col">Active</th>
                <th scope="col">Visible</th>
                <th scope="col">Transactionable</th>
                <th scope="col">Category</th>
                <th scope="col">Menu</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="branch in branches" :key="branch.id">
                <td>{{ branch.name.en }}</td>
                <td>{{ branch.name.es }}</td>
                <td>{{ branch.order }}</td>
                <td>{{ branch.isActive }}</td>
                <td>{{ branch.isVisible }}</td>
                <td>{{ branch.isTransactionable }}</td>
                <td>
                    <button class="btn btn-secondary mx-1" @click="category(branch.id)">See</button>
                </td>
                <td>
                    <button class="btn btn-secondary mx-1" @click="menu(branch.id)">See</button>
                </td>
                <td>
                    <button class="btn btn-info mx-1" @click="edit(branch.id)">Edit</button>
                    <button class="btn btn-danger mx-1" @click="delete(branch.id)">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { db } from '../../firebaseConfig'
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";

export default {
    setup() {
        const router = useRouter()
        const store = useStore()
        const branches = ref([]);
        
        async function deleteBranch() {
            await store.dispatch('deleteBranch')
        }

        const edit = (id) => {
            saveBranch(id)
            router.push('/branch/add')
        }

        const category = (id) => {
            saveBranch(id)
            router.push('/branch/category')
        }

        const menu = (id) => {
            saveBranch(id)
            router.push('/branch/menu')
        }

        async function saveBranch(id) {
            let data = branches.value.find(x => x.id === id)
            await store.dispatch('addBranch', data)
        }

        function loadData() {
            const q = query(collection(db, ConfigMethods.MAIN_URL), orderBy('order'));
            onSnapshot(q, (querySnapshot) => {
                branches.value = []
                querySnapshot.forEach((doc) => {
                    branches.value.push({ id: doc.id, ...doc.data() })
                });
            });
        }
        
        onMounted(() => {
            loadData()
            deleteBranch()
        })

        return { branches, category, menu, edit };
    }
}
</script>