<template>
    <div class="row">
        <div class="col-12">
            <router-link class="btn btn-primary mb-3" to="/branch/">Back</router-link>
        </div>
    </div>
    <!-- <form ref="form" @submit.prevent="submitForm"> -->
        
    <div class="row">
        <p v-if="errors.length" class="alert alert-danger">
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul class="m-0 list-group">
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </p>
        <form ref="form" @submit.prevent="submitForm" novalidate>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="false">Name</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="true">Explanation</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="false">Logo</a>
                </li>
            </ul>
            <div class="tab-content pt-5" id="tab-content">
                <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
                    <div class="mb-3">
                        <label for="menuCategory">Category</label>
                        <select class="form-select" name="menuCategory" id="menuCategory" v-model="menuForm.category">
                            <option value="null" disabled>Select option</option>
                            <option v-for="(category) in categories" :key="category.id" :value="category.id">
                                {{ category.name.en }} - {{ category.name.es }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label for="menuNameEn">Name EN</label>
                                <input v-model="menuForm.name.en" type="text" class="form-control" id="menuNameEn" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label for="menuNameEs">Name ES</label>
                                <input v-model="menuForm.name.es" type="text" class="form-control" id="menuNameEs" required>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="menuPrice">Price</label>
                        <input v-model="menuForm.price" type="number" class="form-control" id="menuPrice" required>
                    </div>
                    <div class="mb-3">
                        <label for="menuOrder">Order</label>
                        <input v-model="menuForm.order" type="number" class="form-control" id="menuOrder" required>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="menuForm.isActive" type="checkbox" class="form-check-input" id="menuIsActive">
                        <label class="form-check-label" for="menuIsActive">Is Active</label>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="menuForm.isVisible" type="checkbox" class="form-check-input" id="menuIsVisible">
                        <label class="form-check-label" for="menuIsVisible">Is Visible</label>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label for="menuExplanationEn">Explanation EN</label>
                                <editor v-model="menuForm.explanation.en" id="menuExplanationEn"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label for="menuExplanationEs">Explanation ES</label>
                                <editor v-model="menuForm.explanation.es" id="menuExplanationEs"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
                    <div class="input-group mb-3">
                        <input @change="handleFileChange" type="file" accept="image/*" class="form-control" id="inputGroupFile" aria-describedby="inputGroupFile" aria-label="Upload" multiple>
                    </div>
                    <div class="mb-3">
                        <div class="upload__img-wrap">
                            <div class="upload__img-box" v-for="(image, index) in images" :key="index">
                                <div :style="`background-image: url(${image.url})`" class="img-bg">
                                    <div @click="deleteImage(image, index)" class="upload__img-close"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-end">
                    <button type="submit" class="btn btn-success">Submit</button>
                </div>
            </div>
        </form>
    </div>

        
        <!-- <button type="submit" class="btn btn-success">Submit</button> -->
    <!-- </form> -->
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { ref, onMounted, reactive } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification";
import { db, storage } from '../../firebaseConfig'
import { doc, collection, addDoc, updateDoc, query, orderBy, onSnapshot } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import Editor from '@tinymce/tinymce-vue'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()
        const categories = ref([])
        const isEdit = ref(false)
        var id = null
        const multipleImages = ref(true)
        const images = ref([])
        const deletedImages = ref([])
        const files = ref([])
        const urls = ref([])
        const errors = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        const formInitialState = {
            album: [],
            category: null,
            name: {
                en: '',
                es: '',
            },
            price: 0,
            explanation: {
                en: '',
                es: '',
            },
            order: 0,
            isActive: false,
            isVisible: false
        }
        const menuForm = reactive({ ...formInitialState })

        // const back = () => {
        //     router.push('/branch/')
        // }

        const handleFileChange = (e) => {
            if (e.target.files) {
                if( !multipleImages.value ) {
                    if(images.value.length > 0) {
                        deleteImage(images.value[0], 0)
                    }
                    images.value = []
                    files.value = []
                }

                for (const file of e.target.files) {
                    let reader  = new FileReader()
                    files.value.push(file)
                    reader.onloadend = function () {
                        let object = {
                            name: file.name,
                            url: reader.result
                        }
                        images.value.push(object)
                    }
                    reader.readAsDataURL(file)
                }
            }
        }

        const deleteImage = (file, index) => {
            if (isURL(file.url)) {
                deletedImages.value.push(file.url)
            } else {
                for (var i = 0; i < files.value.length; i++) {
                    if (files.value[i].name === file.name) {
                        files.value.splice(i, 1)
                        break
                    }
                }
            }

            // Aquí debe eliminar de menuForm.album
            images.value.splice(index, 1)
        }

        const submitForm = async () => {
            if( isValidForm() ) {
                loader = $loading.show()
                uploadImagesInStorage()
                .then(() => {
                    addToCollection()
                    .then(() => {
                        loader.hide()
                        deleteImagesInStorage()
                    })
                })
            }
        }

        function isValidForm() {
            let error = false
            if (!menuForm.name.en) {
                errors.value.push("Name EN can't be empty")
                error = true
            }

            if (!menuForm.name.es) {
                errors.value.push("Name ES can't be empty")
                error = true
            }

            return !error
        }

        function initForm() {
            Object.assign(menuForm, store.getters.menu.data)
            menuForm.category = menuForm.category.id
            initImages()
        }

        function initImages() {
            if(menuForm.album.length > 0 && menuForm.album != null && menuForm.album != undefined) {
                for (var i = 0; i < menuForm.album.length; i++) {
                    let object = {
                        name: menuForm.album[i],
                        url: menuForm.album[i]
                    }
                    images.value.push(object)
                }
            }
        }

        function isURL(string) {
            let url
            try {
                url = new URL(string)
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        // function resetForm() {
        //     Object.assign(menuForm, ...formInitialState)
        // }

        function deleteImagesInStorage() {
            const deleteFile = async (storageReference) => {
                deleteObject(storageReference)
            }

            try {
                deletedImages.value.forEach((file) => {
                    const storageReference = storageRef(storage, file)
                    deleteFile(storageReference)
                })
            } catch (error) {
                console.log(error);
            }
        }

        async function uploadImagesInStorage() {
            const promises = []
            const uploadFile = async (storageReference, file) => {
                await uploadBytes(storageReference, file)
                await getDownloadURL(storageReference)
                .then((url) => {
                    urls.value.push(url)
                })
            }

            try {
                files.value.forEach((file) => {
                    const { name } = file
                    const storageReference = storageRef(storage, 'menu/' + name)
                    promises.push(uploadFile(storageReference, file))
                })
            } catch (error) {
                console.log(error);
            }
            
            await Promise.all(promises)
        }

        async function addToCollection() {
            if(urls.value.length == 0) {
                if(images.value.length > 0) {
                    menuForm.album = []
                    images.value.forEach((image) => {
                        menuForm.album.push(image.url)
                    })

                } else {
                    menuForm.album = []
                }
            }   else {
                menuForm.album = urls
                images.value.forEach((image) => {
                    if (isURL(image.url)) {
                        menuForm.album.push(image.url)
                    }
                })
            }
            
            if(isEdit.value) {
                let docRef = doc(db, ConfigMethods.MAIN_URL+'/'+id+'/Menu/'+menuForm.id)
                let categoryDocRef = doc(db, `${ConfigMethods.MAIN_URL}/${id}/Category/${menuForm.category}`)
                menuForm.category = categoryDocRef
                
                delete menuForm.id

                await updateDoc(docRef, menuForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                    router.push('/branch/menu/')
                })
                .catch((err) => console.log(err));
            } else {
                await addDoc(collection(db, ConfigMethods.MAIN_URL+'/'+id+'/Menu/'), menuForm)
                .then((docRef) => {
                    console.log(docRef.id)
                    if (docRef.id) {
                        notify({
                            type: "success",
                            title: "It was saved correctly"
                        })
                        router.push('/branch/menu/')
                    }
                })
                .catch((err) => console.log(err));
            }
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/${store.getters.branch.data.id}/Category`), orderBy('order'))
            onSnapshot(q, (querySnapshot) => {
                categories.value = []
                querySnapshot.forEach((doc) => {
                    categories.value.push({ id: doc.id, ...doc.data() })
                });
            });
        }

        onMounted(async () => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/menu/')
            } else {
                id = store.getters.branch.data.id
                loadData()
                if( store.getters.menu.data != null ) {
                    initForm()
                    isEdit.value = true
                }
            }
        });

        return { categories, menuForm, images, handleFileChange, deleteImage, submitForm, errors };
    },
    components: {
        'editor': Editor
    }
}
</script>