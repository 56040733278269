import { createStore } from 'vuex'
import { auth } from './firebaseConfig'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut } from 'firebase/auth'

const store = createStore({
    state: {
        user: {
            loggedIn: false,
            data: null
        },
        branch: {
            data: null
        },
        category: {
            data: null
        },
        menu: {
            data: null
        }
    },

    getters: {
        user(state) {
            return state.user
        },
        branch(state) {
            return state.branch
        },
        category(state) {
            return state.category
        },
        menu(state) {
            return state.menu
        }
    },
    mutations: {
        SET_USER(state, payload) {
            state.user.data = payload
        },
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_BRANCH(state, data) {
            state.branch.data = data
        },
        SET_CATEGORY(state, data) {
            state.category.data = data
        },
        SET_MENU(state, data) {
            state.menu.data = data
        },
    },
    actions: {
        async register(context, { email, password, name }) {
            const { user } = await createUserWithEmailAndPassword(auth, email, password)
            if (user) {
                context.commit('SET_USER', user)
                await updateProfile(user, {
                    displayName: name
                });
            } else {
                throw new Error('Unable to register user')
            }
        },

        async logIn(context, { email, password }) {
            const response = await signInWithEmailAndPassword(auth, email, password)
            if (response) {
                context.commit('SET_USER', response.user)
            } else {
                throw new Error('login failed')
            }
        },

        async logOut(context) {
            await signOut(auth)
            context.commit('SET_USER', null)
        },

        async fetchUser(context, user) {
            context.commit("SET_LOGGED_IN", user !== null);
            if (user) {
                context.commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                context.commit("SET_USER", null);
            }
        },

        async addBranch(context, { id, logo, name, explanation, order, isActive, isVisible, isTransactionable }) {
            context.commit('SET_BRANCH', {
                id: id,
                logo: logo,
                name: {
                    en: name.en,
                    es: name.es,
                },
                explanation: {
                    en: explanation.en,
                    es: explanation.es,
                },
                order: order,
                isActive: isActive,
                isVisible: isVisible,
                isTransactionable: isTransactionable
            });
        },

        async deleteBranch(context) {
            context.commit('SET_BRANCH', null)
        },

        async addCategory(context, { id, name, order, isVisible }) {
            context.commit('SET_CATEGORY', {
                id: id,
                name: {
                    en: name.en,
                    es: name.es,
                },
                order: order,
                isVisible: isVisible
            });
        },

        async deleteCategory(context) {
            context.commit('SET_CATEGORY', null)
        },
        
        async addMenu(context, { id, category, album, name, price, explanation, order, isActive, isVisible }) {
            context.commit('SET_MENU', {
                id: id,
                category: category,
                album: album,
                price: price,
                name: {
                    en: name.en,
                    es: name.es,
                },
                explanation: {
                    en: explanation.en,
                    es: explanation.es,
                },
                order: order,
                isActive: isActive,
                isVisible: isVisible
            });
        },

        async deleteMenu(context) {
            context.commit('SET_MENU', null)
        }
    }


})

// export the store
export default store