<template>
  <div>
    <main>
      <!-- <router-view></router-view> -->
      <router-view></router-view>
      <notifications />
    </main>
  </div>
</template>

<script>
export default {
    computed: {

    },
    components: {
      
    }
}
</script>