<template>
    <div class="row">
        <div class="col-12 text-right">
            <router-link class="btn btn-primary mx-1" to="/branch/">Back</router-link>
            <router-link class="btn btn-success mx-1" to="/branch/menu/add">Add Menu</router-link>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Name (EN)</th>
                <th scope="col">Name (ES)</th>
                <th scope="col">Order</th>
                <th scope="col">Active</th>
                <th scope="col">Visible</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="menu in menus" :key="menu.id">
                <td>{{ menu.name.en }}</td>
                <td>{{ menu.name.es }}</td>
                <td>{{ menu.order }}</td>
                <td>{{ menu.isActive }}</td>
                <td>{{ menu.isVisible }}</td>
                <td>
                    <button class="btn btn-info mx-1 mb-1" @click="edit(menu.id)">Edit</button>
                    <button class="btn btn-danger mx-1 mb-1" @click="delete(menu.id)">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { db } from '../../firebaseConfig'
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";

export default {
    setup() {
        const router = useRouter()
        const store = useStore()
        const menus = ref([]);
        
        async function deleteMenu() {
            await store.dispatch('deleteMenu')
        }

        const edit = (id) => {
            saveCategory(id)
            router.push('/branch/menu/add')
        }

        async function saveCategory(id) {
            let data = menus.value.find(x => x.id === id)
            await store.dispatch('addMenu', data)
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/${store.getters.branch.data.id}/Menu`), orderBy('order'))
            onSnapshot(q, (querySnapshot) => {
                menus.value = []
                querySnapshot.forEach((doc) => {
                    menus.value.push({ id: doc.id, ...doc.data() })
                });
            });
        }
        
        onMounted(() => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/')
            } else {
                loadData()
                deleteMenu()
            }
        })

        return { menus, edit };
    }
}
</script>